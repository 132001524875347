@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

h1 {
	font-size: 2rem;
	font-weight: bold;
}

.welcomePage {
	font-size: 2rem;
	margin-top: 54px;
}

.welcomePageInput {
	width: 51% !important; 
}

form {
	margin-top: 0;
	margin-bottom: 0;
  }

.question {
  position: relative;
  left: 0;
  right: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60vw;
  margin-left: 20%;
  margin-right: auto;
  box-sizing: border-box;
  padding-bottom: 150px;
  padding-top: 9.4vh;
  text-align: left;
  min-height: calc(100% - 4vh - 150px);
  font-size: 1rem;
  animation: fadein 0.3s ease-out;
}

.question p {
  text-align: left;
  display: block;
  width: 100%;
}

.header {
  padding-bottom: 0;
}

li {
  margin-left: 20px;
}

@media (max-width: 640px) {
  .question {
    align-items: flex-start;
  }
}

.indentedList {
  padding-left: 1.3rem;
}

.bold {
  font-weight: bold;
}

.space {
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .question {
    width: 60vw;
  }
}

@media (max-width: 900px) {
  .question {
    margin-top: 10vh;
    min-height: calc(100% - 8vh - 150px);
  }
}

@media (max-width: 640px) {
  .question {
    width: 80vw;
  }
}

fieldset:disabled a {
	background-color:rgba(0, 0, 0, 0.15);
	border: 2px solid rgba(0, 0, 0, 0.15);
}

fieldset:disabled a:hover, fieldset:disabled a:focus {
	background-color:rgba(0, 0, 0, 0.15);
	color: white;
	border: 2px solid rgba(0, 0, 0, 0.15);
	filter: none;
}

.buttonwrap {
	margin-bottom: 20px;
}

.logoContainer {
	width: 50%;
	min-width: 400px;
}

.image {
	text-decoration: underline;
	cursor: pointer;
}

.delete {
	float: right;
	color: white;
	cursor: pointer;
	font-size: 21px;
	width: 30px;
	height: 30px;
	text-align: center;
}

.image__container {
	width: 50%;
	min-width: 400px;
}