.bannerActions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 8px;
	margin-top: 24px;
	margin-bottom: 12px;

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
		user-select: none;
	}

	.action {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 8px 10px;
		font-weight: 500;
		text-decoration: none;

		cursor: pointer;
		user-select: none;

		&:hover {
			background-color: #fff;
			color: #fe7843;
		}
	}
}

.currentFile {
	display: flex;
	gap: 6px;
	margin-bottom: 6px;
}

.bannerFileInput {
	display: flex;
	flex-direction: column;
	gap: 8px;

	input[type="file"] {
		display: none;
	}

	label {
		box-sizing: border-box;
		background-color: transparent;
		border: 2px solid #fff;
		padding: 3px 6px;
		margin: 0;
		width: fit-content;
		font-weight: 500;

		cursor: pointer;

		&:hover {
			background-color: #fff;
			border-color: #fff;
			color: #fe7843;
		}
	}
}

.bannerTable {
	display: table;
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	border: 1px solid #fff3;

	td,
	th {
		padding: 4px 8px;
		border: 1px solid #fff3;
	}

	thead {
		background-color: #fff3;
		font-weight: 700;

		tr {
			cursor: default;
			&:hover {
				background-color: unset;
			}
		}
	}

	tbody {
		tr {
			cursor: pointer;
			&:hover {
				background-color: #fff3;
			}
		}
	}

	.actions {
		display: flex;
		align-items: center;
		gap: 8px;

		.badge {
			display: flex;
			align-items: center;
			gap: 4px;
		}

		.action {
			cursor: pointer;
			user-select: none;

			&.button {
				padding: 4px 6px;
				border: 2px solid #fff;
				background-color: transparent;

				font-weight: 500;

				&.transparent {
					border-color: transparent;
				}

				&:hover {
					background-color: #fff;
					border-color: #fff;
					color: #fe7843;
				}
			}
		}
	}
}
