/*******************************MENU******************************/
#exhibitor-menu {
	position: absolute;
	bottom: 0;

	right: 0;
	color: white;
	overflow: hidden;
	pointer-events: none;
	left: 0;
	height: 220px;
}

#exhibitor-menu>* {
	pointer-events: all;
}

@media (max-width: 900px) {
	#exhibitor-menu {
		left: 0;
	}
}

#exhibitor-menu .content {
	background: rgb(254, 157, 123);
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-around;
	width: auto;
	height: 100%;
	top: 0;
	transform: translateX(-100%);
	opacity: 0;
	transition: transform 0.14s ease-in-out, opacity 0.14s ease-in-out;
	margin-left: 76px;
	box-sizing: border-box;
	overflow-x: auto;

	align-items: flex-start;
}

#exhibitor-menu.opened .content {
	transform: translateX(0%);
	opacity: 1;
	backdrop-filter: blur(6px);
}

#exhibitor-menu .content .submenu {
	text-decoration: none;
	font-weight: 600;
	border-bottom: 2px solid transparent;
	margin-top: 2px;
	padding: 0 0.5rem;
	word-break: normal;
	color: black;
	padding-left: 25px;
	border-left: 1px solid #e0e0e0;
	/* width: 250px; */
}

#exhibitor-menu .content .submenu a {
	display: block;
	margin-top: 5px;
	text-decoration: none;
	font-size: 20px;
}

#exhibitor-menu .content .submenu .second-level-menu {
	font-weight: normal;
	font-size: 18px;
	/* margin-left: 20px; */
}

#exhibitor-menu .content .submenu .third-level-menu {
	/* margin-left: 40px; */
	font-weight: normal;
	font-size: 14px;
	margin-left: 15px;
}

#exhibitor-menu .content .submenu a:hover {
	border-bottom: 2px solid white;
}

#exhibitor-menu .trigger {
	width: 76px;
	left: 0;
	top: 0;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.1);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
	user-select: none;
	cursor: pointer;
	transition: background 0.14s ease-in-out;
}

#exhibitor-menu .trigger::before {
	content: "";
	background: url(./images/left-arrow.svg);
	width: 30px;
	height: 30px;
	left: 36px;
	position: absolute;
	transition: transform 0.14s ease-in-out;
	opacity: 0.8;
	transform: rotateZ(180deg);
}

#exhibitor-menu.opened .trigger {
	background: rgb(255, 167, 132);
}

#exhibitor-menu.opened .trigger::before {
	transform: rotateZ(0deg);
}

#exhibitor-menu .trigger span {
	position: relative;
	transform: rotateZ(-90deg);
	text-transform: uppercase;
	left: -16px;
	font-weight: 600;
}

#exhibitor-menu::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

.inactive {
	color: #c0c0c0;
}

@media (max-width: 900px) {
	#exhibitor-menu {
		height: 500px;
	}
}

/******************************TABLE**************************/

.exhibitorTableWrap {
	width: 100%;
	margin-bottom: 0px;
}

.exhibitorTableWrap a {
	text-decoration: none;
	font-weight: normal;
}

.schoolTable>ul>a>li label {
	cursor: pointer;
}

.schoolTable>ul>a>li:hover {
	background: rgba(255, 255, 255, 0.1);
}

.exhibitorTableWrap .exhibitorTableHeader {
	float: left;
	font-weight: bold;
	margin-top: 15px;
}

.exhibitorTableWrap .exhibitorTableButton {
	float: right;
	font-weight: bold;
	margin-top: 10px;
	margin-right: 13px;
	padding-right: 17px;
	cursor: pointer;
	position: relative;
}

.exhibitorTableWrap .exhibitorTableButton::after {
	content: "";
	/* background: url(/static/media/left-arrow.474c60dc.svg); */
	background: url(./images/left-arrow.svg);
	transform: rotateZ(180deg);
	width: 15px;
	height: 15px;
	right: -2px;
	top: 10px;
	position: absolute;
	opacity: 0.8;
}

.exhibitorTableWrap .schoolTableWrap {
	margin: 0 auto;
	margin-top: 55px;
	width: 90%;
}

.schoolTableWrap {
	width: 100% !important;
}

.schoolTable-input-height {
	height: 2rem;
}


/********************************SMALL BUTTON*****************************/

.small-button:hover,
.small-button:focus {
	background-color: white;
	-webkit-filter: grayscale(0%) brightness(100%);
	filter: grayscale(0%) brightness(100%);
	color: #fe7843;
}

.exhibitorTableWrap .exhibitorTableButton:focus::after {
	background-color: #fe7843;
}

.small-button {
	padding: 5px;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	-webkit-filter: grayscale(100%) brightness(200%);
	filter: grayscale(100%) brightness(200%);
	-webkit-transition: opacity 0.14s ease-out;
	transition: opacity 0.14s ease-out;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
}

.buttonwrap-end {
	justify-content: flex-end;
}

.buttonwrap {
	margin-top: 14px;
}

.button {
	height: 33px;
	border-radius: 0px;
}

.button-left,
.button-right {
	display: inline-block;
	text-decoration: underline;
	cursor: pointer;
}

.arrow-svg {
	fill: white;
	background-color: rgba(0, 0, 0, 0);
	width: 19px;
	height: auto;
	vertical-align: middle;
}

.arrow--left {
	margin-left: 10px;
}

.arrow--right {
	margin-right: 10px;
}

fieldset input[type="text"],
fieldset input[type="number"],
fieldset input[type="date"],
fieldset input[type="email"],
fieldset input[type="password"],
fieldset input[type="tel"],
select {
	border-radius: 0;
}

/**********************************TEXTAREA**********************************/

fieldset textarea {
	border: 2.5px solid transparent;
	border-right-color: transparent;
	border-right-style: solid;
	border-right-width: 2.5px;
	border-left-color: transparent;
	border-left-style: solid;
	border-left-width: 2.5px;
	border-left: 0;
	border-right: 0;
	padding: 0.35rem;
	border-radius: 3px;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
	width: 100%;
	margin: 0 0 1rem 0;
	box-sizing: border-box;
}

fieldset label>input[type="checkbox"]+span::before,
fieldset label>input[type="radio"]+span::before {
	position: relative;
	content: "";
	display: inline-block;
	width: 1rem;
	height: 1rem;
	border: 2px solid rgba(255, 255, 255, 0.5);
}

fieldset input[type="checkbox"]:checked+span::before {
	content: "✔";
	background: none;
	padding-top: 0;
	line-height: 0.9;
}


/**********************************Tabs********************************/
.react-tabs__tab-list {
	display: flex;
}

.react-tabs__tab {
	margin-left: 0px;
}

/*********************************BRAND****************************/
#brand--exhibitor {
	left: 0;
	position: absolute;
	width: 130px;
	top: 0;
	user-select: none;
	pointer-events: none;
}

#brand--exhibitor .info {
	padding-right: 0;
	font-size: 1rem;
	font-weight: 400;
	margin-top: 0.5rem;
	color: white;
	text-align: left;
	text-align: right;
	animation: fadein 0.1s ease-out;
}

#brand--exhibitor img {
	margin-top: 1em;
	margin-left: 1em;
	height: 93px;
	width: 93px;
	position: relative;
}

@media (max-width: 900px) {
	#brand--exhibitor {
		width: 80px;
	}

	#brand--exhibitor img {
		width: 80px;
		height: 80px;
	}

	#brand--exhibitor .info {
		display: none;
	}
}

fieldset {
	margin-left: 0;
	padding-left: 0;
}

p {
	margin-top: 0px;
	margin-bottom: 15px;
}

ol {
	margin-top: 0px;
	margin-bottom: 0px;
}

h2 {
	margin-bottom: 5px;
}

a,
button {
	font-weight: normal;
	color: white;
	background-color: rgba(0, 0, 0, 0);
}

.saveButtonRight {
	position: absolute;
	right: 0px;
}

fieldset input:checked+label::before {
	background: white;
}

fieldset input[type="checkbox"]+label::before,
fieldset input[type="radio"]+label::before {
	position: relative;
	content: "";
	display: inline-block;
	width: 1rem;
	height: 1rem;
	border: 2px solid rgba(255, 255, 255, 0.5);
	margin-right: 20px;
}

fieldset input[type="radio"]+label::before {
	content: "";
	border-radius: 1rem;
}

fieldset input[type="checkbox"]:checked+label::before {
	content: "✔";
	background: none;
	padding-top: 0;
	line-height: 0.75;
}

fieldset input[type="checkbox"]+label::after,
fieldset input[type="radio"]+label::after {
	opacity: 0;
}

.notification-danger,
.notification-success,
.notification-warning {
	background-color: #fd8554;
	border-left: 30px solid #bd1120;
}

.notification-danger {
	border-left: 30px solid #bd1120;
}

.notification-success {
	border-left: 30px solid #70bf54;
}

.notification-warning {
	border-left: 30px solid rgb(187, 123, 173);
}

.notification-success .notification-close {
	background-color: #70bf54;
}

.notification-danger .notification-close {
	background-color: #fd8554;
}

.notification-warning .notification-close {
	background-color: rgb(187, 123, 173);
}

.notification-item {
	box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.2)
}

.notification-item .notification-close::after {
	-webkit-transform: translate(-52%, -59%);
	transform: translate(-52%, -59%);
	font-size: 21px;
}

.notification-item .notification-content {
	padding: 20px 17px;
	box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.2)
}

.logoInTable {
	text-decoration: underline !important;
}

.logoInTable:hover {
	background: rgba(255, 255, 255, 0.1);
}

.schoolTable>ul>li.nohover:hover {
	background-color: rgba(0, 0, 0, 0);
}

.schoolTable>ul>li>a>label:hover {
	background: rgba(255, 255, 255, 0.1);
}

.schoolTable>ul>li.nohover label {
	cursor: default;
}

.schoolTable>ul>li.nohover a label {
	cursor: pointer;
}


.no_underline {
	text-decoration: none;
}