.app {
	position: absolute;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	background: #fe7843 url(./images/webp/blanko.webp) repeat;
	background-size: cover;
	background-position: 0 0;
	font-size: 16px;
}

.app-background-invitation {
	background-image: url(./images/webp/BG_Einladungssystem.webp);
	background-repeat: no-repeat;
}

@media (max-width: 900px) {
	.app {
		font-size: 14px;
		font-weight: 500;
	}
}

::selection {
	background: white;
	color: #fe7843;
}

#root {
	margin: 5vh 5vw;
	position: relative;
	width: 90vw;
	height: 90vh;
	box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
}

h1 {
	font-size: 5rem;
	line-height: 90%;
	font-weight: normal;
	user-select: none;
}

@media (max-width: 900px) {
	h1 {
		font-size: 3rem;
	}
}

hr {
	border: 1px solid white;
	border-bottom: 0;
	opacity: 0.5;
}

#content {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	overflow-y: auto;
	overflow-x: hidden;
}

#brand {
	position: absolute;
	width: 185px;
	right: 0;
	top: 0;
	user-select: none;
	pointer-events: none;
}

#brand img {
	height: 185px;
	width: 185px;

	position: relative;
}

#brand .info {
	margin-top: 0.5rem;
	color: white;
	font-weight: 600;
	text-align: left;
	padding-right: 1rem;
	text-align: right;
	animation: fadein 0.1s ease-out;
}

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}


@media (max-width: 900px) {
	#brand {
		width: 80px;
	}

	#brand img {
		width: 80px;
		height: 80px;
	}

	#brand .info {
		display: none;
	}
}

/*
STATUS
*/
/* before, these were the small screen CSS settings
 * now, they are supposed to be the general settings
*/
/* @media (max-width: 900px) { */
#status {
	user-select: none;
	display: flex;
	left: 0;
	width: 100%;
	height: 78px;
	top: 0;
	flex-direction: row;
	border-right: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.4);
	margin-bottom: 2em;
	position: static;
	background: none;
}

#status .steps {
	flex-direction: column;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: 0;
	margin-left: 10px;
}

#status .steps .step {
	display: flex;
	justify-content: center;
	align-items: center;
	color: rgba(255, 255, 255, 0.22);
	flex: 0 1 48px;
	min-height: 25px;
	width: 48px;
	height: 78px;
}

#status .steps a {
	text-decoration: none;
	font-weight: bold;
}

#status .steps a .step {
	color: rgba(255, 255, 255, 0.4);
}

#status .steps a .step:hover {
	background: rgba(255, 255, 255, 0.1);
}

#status .steps .step::after {
	content: "";
	width: 9px;
	height: 9px;
	border-radius: 4px;
	background: rgba(255, 255, 255, 0.4);
	position: absolute;
	position: relative;
	bottom: -39px;
	right: 20%;
}

#status .steps .step.active::after {
	background: rgba(255, 255, 255, 1);
}

/* } */

#menu {
	position: absolute;
	bottom: 0;
	height: 120px;

	left: 78px;
	right: 0;
	color: white;
	overflow: hidden;
	pointer-events: none;
}

#menu>* {
	pointer-events: all;
}

@media (max-width: 900px) {
	#menu {
		left: 0;
	}
}

#menu .content {
	background: rgba(255, 255, 255, 0.3);
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	width: auto;
	height: 100%;
	top: 0;
	transform: translateX(100%);
	opacity: 0;
	transition: transform 0.14s ease-in-out, opacity 0.14s ease-in-out;
	margin-right: 76px;
	box-sizing: border-box;
	overflow-x: auto;
}

#menu.opened .content {
	transform: translateX(0%);
	opacity: 1;
	backdrop-filter: blur(6px);
}

#menu .content a {
	color: white;
	text-decoration: none;
	font-weight: 600;
	border-bottom: 2px solid transparent;
	margin-top: 2px;
	padding: 0 0.5rem;
	word-break: normal;
	flex: 1;
	text-align: center;
	align-self: stretch;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#menu .content a:hover {
	border-bottom: 2px solid white;
}

#menu .trigger {
	width: 76px;
	right: 0;
	top: 0;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.1);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
	user-select: none;
	cursor: pointer;
	transition: background 0.14s ease-in-out;
}

#menu .trigger::after {
	content: "";
	background: url(./images/left-arrow.svg);
	width: 30px;
	height: 30px;
	left: 10px;
	position: absolute;
	transition: transform 0.14s ease-in-out;
	opacity: 0.8;
}

#menu.opened .trigger {
	background: rgb(255, 167, 132);
}

#menu.opened .trigger::after {
	transform: rotateZ(180deg);
}

#menu .trigger span {
	position: relative;
	transform: rotateZ(-90deg);
	text-transform: uppercase;
	left: 10px;
	font-weight: 600;
}

#menu::after {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	background: green;
}

@media (max-width: 900px) {
	#menu {
		height: 78px;
	}
}

@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 0.6;
	}
}

#counter {
	opacity: 0.04;
	position: absolute;
	left: 10%;
	width: 50%;
	max-width: 500px;
	height: 50%;
	max-height: 500px;
	top: 50%;
	transform: translateY(-50%);
	filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
}

@media (max-width: 1200px) {
	#counter {
		opacity: 0.14;
	}
}

@media (max-width: 900px) {
	#counter {
		opacity: 0.14;
	}
}

#counter span,
#counter div {
	width: 100% !important;
	height: 100% !important;
	background-size: auto 100%;
	background-repeat: no-repeat;
	position: absolute;
}

.cross-fade-leave {
	opacity: 1;
}

.cross-fade-leave.cross-fade-leave-active {
	opacity: 0;
	transition: opacity 0.6s ease-in;
}

.cross-fade-enter {
	opacity: 0;
}

.cross-fade-enter.cross-fade-enter-active {
	opacity: 1;
	transition: opacity 0.6s ease-in;
}

.cross-fade-height {
	transition: height 0.4s ease-in-out 0.3s;
}

#loader {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 100;
	display: flex;
}

.buttonwrap {
	margin-top: 3rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.button {
	border: 2px solid white;
	border-radius: 3px;
	height: 45px;
	width: 180px;
	background-size: 20px;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	filter: grayscale(100%) brightness(200%);
	transition: opacity 0.14s ease-out;

	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
}

.approve {
	width: 97%;
	height: 40px;
}

.button:hover,
.button:focus {
	background-color: white;
	filter: grayscale(0%) brightness(100%);
	color: #fe7843;
}

.button.right {
	background-image: url(./images/right-arrow-b.svg);
}

.button.left {
	background-image: url(./images/left-arrow-b.svg);
}

.button.disabled {
	cursor: not-allowed;
	opacity: 0.5;
}

fieldset {
	border: none;
	width: 100%;
}

fieldset legend {
	margin-bottom: 0.5rem;
	padding: 0;
}

fieldset .row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding: 0 0.5rem;
	margin: 0 -0.5rem 0.1rem;
	transition: background 0.14s ease-out;
}

fieldset input[type="radio"]:hover+label,
fieldset input[type="checkbox"]:hover+label,
fieldset input[type="radio"]:focus+label,
fieldset input[type="checkbox"]:focus+label {
	background: rgba(255, 255, 255, 0.1);
}

fieldset .row>* {
	cursor: pointer;
}

fieldset input[type="radio"],
fieldset input[type="checkbox"] {
	opacity: 0;
	position: absolute;
	left: 100%;
}

fieldset input+label {
	width: calc(100% - 1rem);
}

fieldset label {
	display: flex;
	align-items: center;
	padding: 0.5rem 0;
	width: 100%;
	padding: 0.5rem;
	margin: 0 -0.5rem;
}

fieldset label span {
	position: relative;
	flex: 1;
	padding-right: 0.5rem;
}

fieldset input:checked+label::after {
	background: white;
}

fieldset input[type="checkbox"]+label::after,
fieldset input[type="radio"]+label::after {
	position: relative;
	content: "";
	display: inline-block;
	width: 1rem;
	height: 1rem;
	border: 2px solid rgba(255, 255, 255, 0.5);
}

fieldset input[type="radio"]+label::after {
	content: "";
	border-radius: 1rem;
	margin-left: 1rem;
}

fieldset input[type="checkbox"]:checked+label::after {
	content: "✔";
	background: none;
	padding-top: 0;
	line-height: 0.75;
}

fieldset input[type="text"],
fieldset input[type="number"],
fieldset input[type="date"],
fieldset input[type="email"],
fieldset input[type="password"],
fieldset input[type="tel"],
select {
	border: 2.5px solid transparent;
	border-left: 0;
	border-right: 0;
	padding: 0.35rem;
	border-radius: 3px;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
	width: 100%;
	margin: 0 0 1rem 0;
	box-sizing: border-box;
}

@keyframes shake {

	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}

fieldset li {
	list-style: none;
}

fieldset button,
fieldset input[type="button"] {
	padding: 0.5rem;
	border: none;
	background: white;
	color: black;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	margin: 2rem 0 1rem 0;
	width: calc(100%);
}

fieldset button[disabled],
fieldset input[disabled],
fieldset button[readonly],
fieldset input[readonly] {
	opacity: 0.85;
	color: black;
	color: grey;
}

fieldset div[class="validate-entry"] {
	display: flex;
	place-content: space-between
}

fieldset input.invalid {
	/* background: rgb(247, 145, 145); */
	/* color: rgba(200, 10, 10, 0.95); */
	border-bottom-color: rgba(209, 9, 9, 0.842);
	color: rgba(209, 9, 9, 0.842);
	/* color: white; */
	/* box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.15); */
	/* font-weight: 500; */
	animation: shake 0.3s linear both;
}

fieldset select.invalid {
	/* background: rgb(247, 145, 145); */
	/* color: rgba(200, 10, 10, 0.95); */
	border-bottom-color: rgba(209, 9, 9, 0.842);
	color: rgba(209, 9, 9, 0.842);
	/* color: white; */
	/* box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.15); */
	/* font-weight: 500; */
	animation: shake 0.3s linear both;
}

fieldset input::selection {
	color: white;
	background: #fe7843;
}

form {
	width: 100%;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

a {
	color: white;
	font-weight: bold;
}

.simplebar-content {
	min-height: 100%;
}

.simplebar-content .content-switch>*:only-of-type {
	position: relative;
}

.guest {
	margin-bottom: 1rem;
}

.guest:not(:last-child) {
	border-bottom: 1px solid white;
}

ul {
	padding-left: 0;
}

@keyframes growin {
	0% {
		transform: scaleY(0);
		opacity: 0;
	}

	100% {
		transform: scaleY(1);
		opacity: 1;
	}
}

button,
input[type="button"] {
	cursor: pointer;
	border: 1px solid #A0A0A0;
}

button:hover,
input[type="button"]:hover,
button:focus,
input[type="button"]:focus {
	box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.2);
}

.guest {
	height: auto;
	transform-origin: top;

	transition: height 0.2s auto;
}

.guestlist {}

.content-switch {
	min-height: 100%;
	position: relative;
}

.content-switch>* {
	min-height: 100%;
	position: absolute;
	margin: 0 auto;
	right: 0;
	left: 0;
	top: 0;
}

.simplebar-scrollbar:before {
	background-color: white !important;
}

.bold {
	font-weight: bold;
	border-bottom: 3px solid white;
}

.bold:hover {
	background: rgba(255, 255, 255, 0);
}

table {
	border-collapse: collapse;
	width: 100%;
}

td {
	border-right: 3px solid white;
	vertical-align: center;
	height: 40px;
	padding-left: 20px;
}

td:last-child {
	border-right: none;
}

tr:hover,
tr:focus {
	background: rgba(255, 255, 255, 0.1);
}

.noBorder {
	border: none;
}

.addGroup {
	padding: 0.5rem;
	border: none;
	background: white;
	color: black;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	margin: 0 0 1rem 0;
	width: 100%;
}


/*****************FOOTER*******************************/

.footer {
	position: relative;
	margin-right: auto;
	margin-top: 55px;
}

.footer p a {
	margin-right: 30px;
}


/****************ANFAHRT***************************************/
.link--large {
	font-size: 1.3rem;
}

/**************ANMELDECODE************************************/

.anmeldecode {
	border-left: 1px solid rgba(255, 255, 255, 0.2);
	border-right: 1px solid rgba(255, 255, 255, 0.2);
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	display: none;
}

.anmeldecode span {
	margin-left: 10px;
}

li.anmeldecode input {
	margin-left: 20px;
	margin-top: 7px;
	margin-bottom: 7px;
	width: 250px;
}

li.anmeldecode a {
	margin-right: 10px;
	margin-top: 4px;
	float: right;
}

.show {
	display: block;
}

/* Infotext unter Inputs zur Erklärung/Anleitung für den Benutzer */
.form-input-infotext {
	display: block;
	margin-top: -12px;
	margin-bottom: 12px;
	font-size: 14px;
	font-weight: 500;
}

/* New Password */
.input-newPassword {
	margin-bottom: 15px;
	height: 2rem;
	padding-left: 10px;
	width: 50%;
}

.newPassword-container {
	display: flex;
	flex-direction: column;
}

.stepButton-right {
	align-self: center;
}