.containerAussteller {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: flex-start;
	align-content: flex-start;
}

.item {
	flex: 1 1 150px;
	margin-right: 5px;
}

.paragraph {
	display: block;
	margin-bottom: 5px;
}

.schoolTableWrap {
	width: 80%;
}

.schoolTable {
	display: grid;
	position: relative;
	grid-template-columns: 30% 20% 30% 20%;
}

.one-grid {
	grid-template-columns: 100%;
}

.two-grid {
	grid-template-columns: 50% 50%;
}

.three-grid {
	grid-template-columns: 33% 33% 33%;
}

.four-grid {
	grid-template-columns: 30% 20% 30% 20%;
}

.five-grid {
	grid-template-columns: 35% 20% 20% 20% 5%;
}

.two-gridexhibits {
	grid-template-columns: 87% 13%;
}

.three-gridexhibits {
	grid-template-columns: 87% 10% 3%;
}

.four-gridexhibits {
	grid-template-columns: 35% 52% 10% 3%;
}

.three-gridlistpages {
	grid-template-columns: 84% 13% 3%;
}

.four-gridlistpages {
	grid-template-columns: 35% 48% 14% 3%;
}

.five-gridlistpages {
	grid-template-columns: 35% 42% 10% 10% 3%;
}

.eight-gridlistpages {
	grid-template-columns: 5.5% 17.5% 21.5% 14.5% 12.5% 12.5% 12.5% 3.5%;
}

.nine-gridlistpages {
	grid-template-columns: 5.5% 11.5% 27.5% 9.5% 7.5% 6.5% 12.5% 8.5% 11%;
}

.nine-gridlistpagesworkshops {
	grid-template-columns: 8% 18.5% 10.5% 18.5% 9% 9.5% 6.5% 8.5% 11%;
}

.two-grid-approval {
	grid-template-columns: 15% 15%;
}

.schoolTable>ul {
	grid-column: 1/6;
}

.schoolTable>ul.sixCols {
	grid-column: 1/7;
}

.schoolTable>ul.eightCols {
	grid-column: 1/9;
}

.schoolTable>ul.nineCols {
	grid-column: 1/10;
}

.bookinglistwrapper label {
	cursor: default !important;
}

.bookinglistwrapper label.number {
	display: block;
	text-align: right;
	padding-right: 12px;

}

.contribution-grid {
	grid-template-columns: 47% 47% 6%;
	margin-left: 0px;
}

.booths-grid {
	grid-template-columns: 30% 9% 30% 6.5% 10.5% 14%;
}

.steles-grid {
	grid-template-columns: 75% 20% 5%;
}

.furnishings-grid {
	grid-template-columns: 100%;
}

.sites-grid {
	grid-template-columns: 40% 27.5% 27.5% 5%;
}

.training-grid {
	grid-template-columns: 70% 15% 15%;
}

.study-grid {
	grid-template-columns: 70% 30%;
}

.studyfields-grid {
	grid-template-columns: 55% 15% 15% 15%;
}

.passes-grid {
	grid-template-columns: 86% 8% 5%;
}

.schoolTable>div,
.schoolTable>label,
.schoolTable>a>label {
	padding: 0.5rem;
	margin: 0;
	box-sizing: border-box;
	word-break: break-word;
}

.schoolTable a label:first-of-type {
	text-decoration: underline;
}

.schoolTable>div,
.schoolTable>ul li label {
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-left: none;
}

.schoolTable>div:nth-child(5n+1),
.schoolTable>ul li label:nth-child(5n+1) {
	border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.schoolTable ul li,
.schoolTable ul li a {
	word-break: normal;
	margin-left: 0px;
}

.schoolTable>ul {
	grid-column: 1/6;
}

.schoolTable>ul>li label {
	cursor: pointer;
}

.schoolTable>ul>li.booth-state label {
	cursor: default;
}

.schoolTable>ul>li:hover {
	background: rgba(255, 255, 255, 0.1);
}

.schoolTable>ul>li.booth-state:hover {
	background: none;
}

.header {
	font-weight: bold;
	padding-bottom: 1rem;
	background-color: rgba(255, 255, 255, 0.2);
}

.field:checked+li {
	background: rgba(255, 255, 255, 0.2);
}

.field:checked+li:hover {
	background: rgba(255, 255, 255, 0.3);
}

.head {
	font-weight: bold;
	background: rgba(255, 255, 255, 0.1);
}

.field+.schoolTable::after {
	content: "";
	position: absolute;
	right: 1.2rem;
	;
	top: 50%;
	transform: translateY(-50%);
	width: 1rem;
	height: 1rem;
	border: 2px solid rgba(255, 255, 255, 0.5);
	pointer-events: none;
}

.field:checked+.schoolTable::after {
	content: "✔";
	padding-top: 0;
	line-height: 0.75;
}

h1,
h2,
h3,
h4,
h5,
.header {
	word-break: break-word;
}

.close {
	cursor: pointer;
	position: absolute;
	display: block;
	padding: 2px 5px;
	line-height: 20px;
	right: -10px;
	top: -10px;
	font-size: 24px;
	background: #ffffff;
	border-radius: 18px;
	border: 1px solid #cfcece;
	color: black;
}