.indicator {
	display: flex;
	align-items: center;
	padding: 2px 4px;
	padding-right: 8px;
	gap: 6px;
	width: max-content;
}

.indicator svg {
	background-color: rgba(255, 255, 255, 0.75);
	border-radius: 20px;
}

.indicator.red svg {
	color: #e22c2c;
}

.indicator.green svg {
	color: #2b8816;
}