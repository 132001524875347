.table {
	display: table;
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	border: 1px solid #fff3;

	td,
	th {
		padding: 4px 8px;
		border: 1px solid #fff3;
	}

	thead {
		background-color: #fff3;
		font-weight: 700;

		tr {
			cursor: default;
			&:hover {
				background-color: unset;
			}
		}
	}

	tbody {
		tr {
			&.interactive {
				cursor: pointer;
			}

			&:hover {
				background-color: #fff3;
			}
		}
	}

	.actions {
		display: flex;
		align-items: center;
		gap: 8px;

		.badge {
			display: flex;
			align-items: center;
			gap: 4px;
		}

		.action {
			cursor: pointer;
			user-select: none;

			&.button {
				padding: 4px 6px;
				border: 2px solid #fff;
				background-color: transparent;

				font-weight: 500;

				&.transparent {
					border-color: transparent;
				}

				&:hover {
					background-color: #fff;
					border-color: #fff;
					color: #fe7843;
				}
			}
		}
	}
}
