.checkbox {
	display: flex;
	align-items: center;
	justify-content: center;

	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	-ms-appearance: none;
	margin: 0;

	opacity: 1 !important;
	position: relative !important;

	width: 18px;
	height: 18px;
	border: 2px solid rgba(255, 255, 255, 0.5);

	cursor: pointer;

	&:checked::after {
		content: "✔";
		color: #fff;
		top: 0;
		left: 0;
	}
}
