.schoolTable {
	display: grid;
	position: relative;
	grid-template-columns: 22% 22% 22% 22% 12%;
}

.schoolTable > div,
.schoolTable > label {
	padding: 0.5rem;
	margin: 0;
	box-sizing: border-box;
	word-break: break-all;
}

.schoolTable > div,
.schoolTable > ul li label {
	border: 1px solid rgba(255,255,255,0.2);
	border-left: none;
}
.schoolTable > div:nth-child(5n+1),
.schoolTable > ul li label:nth-child(5n+1) {
	border-left: 1px solid rgba(255,255,255,0.2);
}

.schoolTable > ul {
	grid-column: 1/6;
}
.schoolTable > ul > li label {
	cursor: pointer;
}
.schoolTable > ul > li:hover {
	background: rgba(255,255,255,0.1);
}

.schoolTable li {
	margin-left: 0px;
}

.header {
	font-weight: bold;
	padding-bottom: 1rem;
}

.field:checked + li {
	background: rgba(255,255,255,0.2);
}

.field:checked + li:hover label {
}
.field:checked + li:hover {
	background: rgba(255,255,255,0.3);
}

.head {
	font-weight: bold;
	background:rgba(255,255,255,0.1);
}


.field + .schoolTable::after {
	content: "";
	position: absolute;
	right: 1.2rem;;
	top: 50%;
	transform: translateY(-50%);
	width: 1rem;
	height: 1rem;
	border: 2px solid rgba(255,255,255,0.5);
	pointer-events: none;
}
.field:checked + .schoolTable::after {
	content: "✔";
	padding-top: 0;
	line-height: 0.75;
}


